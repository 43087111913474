<template>
  <Html
    :lang="head.htmlAttrs?.lang ? head.htmlAttrs.lang : 'nl-NL'"
    :dir="head.htmlAttrs?.dir"
  >
    <Head>
      <!-- Generate the favicons on https://favicon.new/ and add the files to the public folder -->
      <Link
        rel="apple-touch-icon"
        sizes="180x180"
        :href="'/favicon/' + region?.attributes.slug + '/apple-touch-icon.png'"
      />
      <Link
        rel="icon"
        type="image/png"
        sizes="32x32"
        :href="'/favicon/' + region?.attributes.slug + '/favicon-32x32.png'"
      />
      <Link
        rel="icon"
        type="image/png"
        sizes="16x16"
        :href="'/favicon/' + region?.attributes.slug + '/favicon-16x16.png'"
      />
      <Link
        rel="icon"
        type="image/png"
        sizes="48x48"
        :href="'/favicon/' + region?.attributes.slug + '/favicon.ico'"
      />

      <link
        rel="preload"
        href="/fonts/roc-grotesk-400.woff2"
        as="font"
        type="font/woff2"
        crossorigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/roc-grotesk-600.woff2"
        as="font"
        type="font/woff2"
        crossorigin="anonymous"
      />
      <link rel="stylesheet" href="/css/fonts.css" />

      <Link rel="manifest" href="/site.webmanifest" />
      <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5e9fd8" />
      <Meta name="msapplication-TileColor" content="#ffffff" />
      <Meta name="theme-color" content="#ffffff" />

      <template v-for="link in head.link" :key="link.id">
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>

    <Body
      :style="`--color-primary:${region?.attributes.primaryColor}; --color-secondary:${region?.attributes.secondaryColor}; `"
    >
      <SiteHeader />
      <main class="min-h-screen">
        <slot />
      </main>
      <SiteFooter />
    </Body>
  </Html>
</template>

<script setup lang="ts">
// const route = useRoute();
// const { t, locale } = useI18n();
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: false,
});

const { region } = useStaticData();
</script>
